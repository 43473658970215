import {Store} from "../types";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {getDistanceInKm, isMobileBrowser} from "../utils/helper";
import {GlobeAltIcon, HeartIcon, MapIcon, MapPinIcon} from "@heroicons/react/24/outline";
import {HeartIcon as HeartIconFilled} from "@heroicons/react/24/solid";
import {addFavorite, removeFavorite} from "../app/favoriteSlice";
import React from "react";
import {useTranslation} from "react-i18next";

export default function StoreCard({ store, onClick }: {store: Store, onClick?: (id: string) => void}) {

    const { t } = useTranslation("storecard");
    const favorites = useAppSelector((state) => state.favorites);

    const myLocation = useAppSelector((state) => state.myLocation);
    const dispatch = useAppDispatch();

    const handleTriggerFavorite = () => {
        let savedFavorites: string[] = JSON.parse(localStorage.getItem("favorites") || "[]");
        if (favorites.ids.includes(store.store_id)) {
            dispatch(removeFavorite(store.store_id));
            savedFavorites = savedFavorites.filter((item) => item !== store.store_id);
        } else {
            dispatch(addFavorite(store.store_id));
            savedFavorites.push(store.store_id);
        }

        localStorage.setItem("favorites", JSON.stringify(savedFavorites));

    }

    const handleClick = React.useCallback(() => {
        if (onClick !== undefined && onClick !== null) {
            onClick(store.store_id);
        }
    }, [onClick, store.store_id]);
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const currentDate = new Date();
    let currentDay = currentDate.getDay() - 1;
    if (currentDay === -1) {
        currentDay = 6;
    }
    const dayStrings: {[name: string]: string} = {
        monday: t('Mon'),
        tuesday: t('Tue'),
        wednesday: t('Wed'),
        thursday: t('Thu'),
        friday: t('Fri'),
        saturday: t('Sat'),
        sunday: t('Sun'),
    }

    return (
        <div

            // className="flex flex-col rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 md:max-w-xl md:flex-row min-h-24">
            className="flex flex-col grow gap-3 w-full md:h-auto min-h-24 p-3 rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <h5 className="mb-2 text-xl font-medium text-neutral-800 dark:text-neutral-50">
                {store.name}
            </h5>
            <div className="w-full flex flex-row justify-between cursor-pointer" onClick={handleClick}>
                <div className="flex flex-col gap-3 text-sm">
                    <img className="w-20 h-20 rounded" src={`/img/stores/${store.store_name}.png`} alt=""/>
                    <div className="flex flex-row gap-1 text-neutral-800 dark:text-neutral-50">
                        <GlobeAltIcon className="w-5 h-5" />
                        <p>{store.city}</p>
                    </div>
                    <div className="flex flex-row gap-1 text-neutral-800 dark:text-neutral-50">
                        <MapPinIcon className="w-5 h-5" />
                        <p>{store.address}</p>
                    </div>
                    <div className="flex flex-row gap-1 text-neutral-800 dark:text-neutral-50">

                        <MapIcon className="w-5 h-5" />

                        {
                            (myLocation.latitude == 0 && myLocation.longitude == 0) ? "--" : getDistanceInKm(store.latitude, store.longitude, myLocation.latitude, myLocation.longitude).toFixed(2)
                        } {t("Km")}
                    </div>
                </div>
                <div className="flex flex-col gap-1 text-sm text-neutral-800 dark:text-neutral-50">

                    {
                        days.map((dayName, index) => {
                            const workingHours = (store.working_hours as any)[dayName]
                            const dayShort = dayStrings[dayName]
                            return (
                                <div key={`${store.store_id}-${index}`}
                                     className={`flex flex-row gap-1 w-full justify-between ${index === currentDay ? "font-bold" : ""}`}>
                                    <span>{dayShort}:</span>
                                    {
                                        workingHours !== false ?
                                            (
                                                <span
                                                    className="text-nowrap">{(workingHours as string[])[0]} - {(workingHours as string[])[1]}</span>
                                            ) : (
                                                <span className="text-nowrap text-warning">{t("Closed")}</span>
                                            )
                                    }
                                </div>
                            )
                        })
                    }

                </div>

            </div>

            <div className="w-full flex flex-row justify-end gap-3">
                <button
                    type="button"
                    onClick={() => {handleTriggerFavorite()}}
                    // className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                    className="inline-block rounded-xl bg-primary-100 p-3 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                >
                    {
                        favorites.ids.includes(store.store_id) ? (<HeartIconFilled className="w-4 h-4" />) : (<HeartIcon className="w-4 h-4" />)
                    }


                </button>
                <button
                    onClick={() =>{
                        window.open(`https://www.google.com/maps/dir/?api=1&destination=${store.latitude},${store.longitude}`, isMobileBrowser() ? "_self" : "_blank")
                    }}
                    type="button"
                    // className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                    className="inline-block  bg-primary-100 p-3 rounded-xl pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                >
                    <MapIcon className="w-4 h-4" />
                </button>
            </div>

        </div>
    );
}